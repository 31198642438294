import { asyncFunc } from "../API/common/asyncFun";
import { setHeaders } from "../API/common/setHeader";
import { apiEndPoints } from "../API/common/endpoints";
const url = import.meta.env.VITE__APP_API;
export const verifyUser = async () => {
  const config = {
    method: "get",
    url: url + apiEndPoints.Admin.verifyUser,
    headers: setHeaders("application/json", true),
  };
  return await asyncFunc(config);
};
