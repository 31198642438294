import React, { Suspense, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { authRoutes, LoginRoutes, UserRoutes } from "./allroutes";
import AdminLayout from "../Layout/Dashboard";
import { Toaster } from 'react-hot-toast';
import ProtectedRoute from "./ProtectedRoute";
import ProtectedRouteTwo from "./ProtectedRouteTwo";
import { useSelector, useDispatch } from "react-redux";
import { setAdmin } from "../Redux/slices/adminSlice";
import { verifyUserReducer } from "../Redux/actions/adminAuth";
import { Spinner } from "react-bootstrap";
const PageNotFound = React.lazy(() => import("../Pages/PageNotFound/PageNotFound"));
const Router = () => {
  const userData = useSelector((store) => store.adminAuth.userData);
 
  const FallbackLoadingComponent = () => {
    return (
      <div className="text-center p-3">
        <Spinner animation="border" variant="primary" />
      </div>
    );
  };

  const dispatch = useDispatch();

  const fetchData = async () => {
    if (userData && userData.jwtToken) {
      
      dispatch(verifyUserReducer());
    } else {
    
      dispatch(setAdmin(null));
    }
  };
  useEffect(() => {
    fetchData();
  }, [userData]);
  return (
    <>
      <Suspense fallback={<FallbackLoadingComponent />}>
        <Routes>
          <Route index element={<Navigate replace to={"login"} />} />
          <Route element={<AdminLayout />}>
            {authRoutes.map((el, index) => (
              <Route
                path={el.path}
                key={el.path}
                element={
                  <ProtectedRoute role={userData?.role}>
                    <el.component />
                  </ProtectedRoute>
                }
              />
            ))}
          </Route>
          {LoginRoutes.map((el, index) => (
            <Route
              path={el.path}
              element={
                <ProtectedRouteTwo isLogged={userData}>
                  <el.component />
                </ProtectedRouteTwo>
              }
              key={el.path}
            />
          ))}
        {UserRoutes.map((el,index)=><Route path={el.path} key={el.path} element={<el.component/>} />)}
        <Route path="*" element={<PageNotFound />} />  
        </Routes>
      </Suspense>
      <Toaster position='top-center' gutter={22} toastOptions={{
      success:{
        duration:3000
      },
      error:{
        duration:5000
      }
    }}/>
    </>
  );
};

export default Router;
