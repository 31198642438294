import { useState } from "react";
import "../../App.scss";
import EditProfileModal from "../Modals/EditProfileModal";
import CommonDashboardHeader from "../CommonHeader/CommonDashboardHeader";
import PrizesDashboard from "./PrizesDashboard";

const Prizes = () => {
  const [modalShowEditProfile, setModalShowEditProfile] = useState(false);

  return (
    <>
      <div className="App">
        <div className="wrapper">
          <div className="content-wrapper h-full">
            <CommonDashboardHeader
              text={"Add, Edit or Remove Prizes."}
              title={"Prize Listing"}
              onClickShow={() => setModalShowEditProfile(true)}
            />

            <PrizesDashboard />
          </div>
        </div>
      </div>
      <EditProfileModal
        show={modalShowEditProfile}
        onHide={() => setModalShowEditProfile(false)}
      />
    </>
  );
};

export default Prizes;
