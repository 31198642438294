import { createAsyncThunk } from "@reduxjs/toolkit";
import { Login as LoginApi, ResendOtp, VerifyOtp } from "../../API/Login/Login";
import { handleApiError } from "../../helper/helperFunction";
import toast, { Toaster } from "react-hot-toast";
import { setAdmin, setAuth } from "../slices/adminSlice";
import { ForgetPassword as ForgetPasswordApi } from "../../API/ForgetPassword/ForgetPassword";
import { changePasswordApi } from "../../API/ResetPassword/ResetPassword";
import { verifyUser } from "../../API/verifyUser";
import { updatePasswordApi } from "../../API/UpdatePassword/UpdatePassword";
import { CredentialChangeApi } from "../../API/CredentialChange/CredentialChange";
import { uploadFile } from "../../API/UploadProfile/UploadProfile";
import { clearData } from "../slices/getAllUserSlice";
import { capitalizeSentence } from "../../helper/helperFunction";
import { clearAdData } from "../slices/advertisementSlice";
import { clearDealData } from "../slices/getAllDeals";
import { clearGraphData } from "../slices/graphSlice";
import { clearRPData } from "../slices/rentlyProSlice";
export const handleLoginAdmin = createAsyncThunk(
  "admin/Login",
  async ({ data, setLoading, cb }, { dispatch }) => {
    try {
      setLoading(true);
      const resp = await LoginApi(data);
      const { success, data:adminInfo } = resp;
      if (success && adminInfo.userData.role === "SuperAdmin") {
        toast.success("Successfully sent the otp");
        cb(adminInfo.userData);
      } else {
        toast.error("Invalid Credentials");
      }
    } catch (error) {
      const message = handleApiError(error);
      toast.error(capitalizeSentence(message));
    } finally {
      setLoading(false);
    }
  }
);

export const handleForgetPassword = createAsyncThunk(
  "admin/forgetPassword",
  async ({ data, cb, setLoading }, { dispatch }) => {
    try {
      setLoading(true);
      const resp = await ForgetPasswordApi(data);
      if (resp) {
        toast.success("Reset password link sent to email");
        //navigate to login
        cb();
      }
    } catch (error) {
      console.log(error);
      const message = handleApiError(error);
      toast.error(capitalizeSentence(message));
    } finally {
      setLoading(false);
    }
  }
);

export const handleChangePassword = createAsyncThunk(
  "admin/reset-password",
  async ({ data, jwtToken, cb }, { dispatch }) => {
    try {
      const resp = await changePasswordApi(data, jwtToken);

      if (resp) {
        toast.success("Password Changed Successfully");
        cb();
      }
    } catch (error) {
      const message = handleApiError(error);
      toast.error(capitalizeSentence(message));
    }
  }
);

export const verifyUserReducer = createAsyncThunk(
  `admin/verify-user`,
  async ({ data }, { dispatch, getState }) => {
    try {
      const state = getState(); // Access the entire Redux state
      const userData = state.adminAuth.userData;

      const res = await verifyUser();

      if (res.success) {
        dispatch(setAuth(true));
        dispatch(setAdmin({ ...res.userData, jwtToken: userData.jwtToken }));
      } else {
        dispatch(setAdmin(null));
      }
    } catch (error) {
      const message = handleApiError(error);
      toast.error(capitalizeSentence(message));
    }
  }
);

export const logOutUser = createAsyncThunk(
  `admin/logout-admin`,
  async (cb, { dispatch, getState }) => {
    try {
      dispatch(setAuth(false));
      dispatch(setAdmin(null));
      dispatch(clearData());
      dispatch(clearAdData());
      dispatch(clearDealData());
      dispatch(clearGraphData());
      dispatch(clearRPData());

      toast.success("User logged out successfully");
    } catch (error) {
      const message = handleApiError(error);
      toast.error(capitalizeSentence(message));
    }
  }
);

export const handleUpdatePassword = createAsyncThunk(
  "admin/changCred",
  async ({ data, setLoading, cb }, { dispatch }) => {
    try {
      setLoading(true);
      const resp = await updatePasswordApi(data);
      if (resp.success) {
        dispatch(setAdmin(null));
        toast.success("Successfully update the password");
        cb();
      }
    } catch (error) {
      const message = handleApiError(error);
      toast.error(message);
    } finally {
      setLoading(capitalizeSentence(message));
    }
  }
);

export const handleCredentialsChange = createAsyncThunk(
  "tenant/changCred",
  async (
    { data, setLoading, cb, setEditShow, EDIT_DETAILS },
    { dispatch, getState }
  ) => {
    try {
      const state = getState(); // Access the entire Redux state
      const userData = state.adminAuth.userData;

      const resp = await CredentialChangeApi(data);

      if (resp.success) {
        const user = resp?.data?.userData;
        dispatch(setAdmin({ ...userData, email: user.email }));
        toast.success("Successfully updated");
        setEditShow(EDIT_DETAILS);
        cb();
      }
    } catch (error) {
      const message = handleApiError(error);
      toast.error(capitalizeSentence(message));
    } finally {
      setLoading(false);
    }
  }
);

export const uploadAdminProfile = createAsyncThunk(
  `tenant/upload-Profile`,
  async ({ file, setLoading, cb }, { dispatch, getState }) => {
    try {
      const state = getState(); // Access the entire Redux store
      const userData = state?.adminAuth?.userData;
      const form_data = new FormData();
      const id = toast.loading("Uploading...");

      form_data.append("profilePic", file);
      const res = await uploadFile(form_data, setLoading, id);
      if (res.success) {
        const user = res?.data?.userData;
        dispatch(setAdmin({ ...userData, profilePic: user?.profilePic }));
        toast.success("Successfully uploaded!", { id: id });
        cb();
      }
    } catch (error) {
      toast.error("Upload failed", { id: id });
      console.log(error);
      const message = handleApiError(error);
      toast.error(capitalizeSentence(message));
    }
  }
);


export const handlePostOtpVerify = createAsyncThunk(
  "admin/otp",
  async ({ data, setIsLoading, nav }, { dispatch }) => {
    try {
      setIsLoading(true);
      const resp = await VerifyOtp(data);
      const { success } = resp;
      if (success) {
        dispatch(setAdmin({ ...resp?.data?.userData }));
        toast.success("Successfully LoggedIn");
        nav()
      } 
    } catch (error) {
      const message = handleApiError(error);
      toast.error(capitalizeSentence(message));
    } finally {
      setIsLoading(false);
    }
  }
);

export const handleResendOtp = createAsyncThunk(
  "admin/otp",
  async ({ data, setIsLoading, cb }, { dispatch }) => {
    try {
      setIsLoading(true);
      const resp = await ResendOtp(data);
      const { success } = resp;
      if (success) {
        toast.success("Otp resent successfully");
      } 
    } catch (error) {
      const message = handleApiError(error);
      toast.error(capitalizeSentence(message));
    } finally {
      setIsLoading(false);
    }
  }
)