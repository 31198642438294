import blankProfile from "/img/BlankImage.svg";
import rentlyLogo from "/rently-logo.svg";
import defaultRentlyIcon from "/img/defaultRentlyIcon.svg";

const imagePack = {
  blankProfile,
  rentlyLogo,
  defaultRentlyIcon,
};

export default imagePack;
