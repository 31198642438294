import { asyncFunc } from "../common/asyncFun";
import { apiEndPoints } from "../common/endpoints";
import { setHeaders } from "../common/setHeader";
const url = import.meta.env.VITE__APP_API;

export const EditPrizeApi = async (data) => {
  const config = {
    method: "put",
    url: url + apiEndPoints.Admin.updatePrize,
    headers:  setHeaders("multipart/form-data", true),
    data,
  };

  return await asyncFunc(config);
};
