import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: null,
  propertyLeads: null,
  details: null,
  query: {
    label: {
      search: "",
      source: [],
      agentId: "",
      property: "",
      status: "",
      refresh: "",
    },
    filter: {
      search: "",
      source: [],
      agentId: "",
      property: "",
      status: "",
      refresh: "",
      maximumBudget: null,
      minBudget: null,
      maxBudget: null,
      trigger: false,
    },
  },
};

const leadsSlice = createSlice({
  name: "lead",
  initialState,
  reducers: {
    setLeadsList: (state, action) => {
      state.list = action.payload;
    },
    setPropertyLeads: (state, action) => {
      state.propertyLeads = action.payload;
    },
    setLeadsDetails: (state, action) => {
      state.details = action.payload;
    },
    setLeadQueryLabel: (state, action) => {
      state.query.label = { ...state.query.label, ...action.payload };
    },
    setLeadQueryFilter: (state, action) => {
      state.query.filter = { ...state.query.filter, ...action.payload };
    },
    resetLeadQuery: (state) => {
      state.query = initialState.query;
    },
    triggerRefresh: (state) => {
      state.query.filter.refresh = !state.query.filter.refresh;
    },
    triggerAction: (state) => {
      state.query.filter.trigger = !state.query.filter.trigger;
    },
    setLeadFilter: (state, action) => {
      state.filter = action.payload;
    },
    clearLeadsData: () => initialState,
  },
});

export const {
  setLeadsList,
  setPropertyLeads,
  setLeadsDetails,
  setLeadQueryLabel,
  setLeadQueryFilter,
  resetLeadQuery,
  triggerRefresh,
  triggerAction,
  clearLeadsData,
} = leadsSlice.actions;
export default leadsSlice.reducer;
