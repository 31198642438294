import { getEnvPath } from "../../helper/helperFunction";
import Table from "../Table/Table";
import Button from "react-bootstrap/Button";
import ViewPrizeModal from "./ViewPrizeModal";
import { useState } from "react";

const PrizeRow = ({ data }) => {
  const [modalShow, setModalShow] = useState(false);
  return (
    <>
      <Table.Row>
        <td>
          <div className="PrizeCompanyLogoContainer ms-12px">
            <img
              src={getEnvPath("companyProfilePic", data.logo)}
              // src="/img/logo.svg"
              alt="DEXTERS"
            />
          </div>
        </td>
        <td>
          <p className="mb-0 fsr-14 fw-700 text-dark">{data.title}</p>
        </td>
        <td>{data.details}</td>
        <td className="">
          <Button
            variant="white"
            className="p-0 text-primary ms-auto fsr-14 fw-700 border-0"
            size="lg"
            onClick={() => setModalShow(true)}
          >
            View Prize
          </Button>
        </td>
      </Table.Row>
      <ViewPrizeModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        data={data}
      />
    </>
  );
};

export default PrizeRow;
