import { Outlet } from "react-router-dom";
import Sidebar from "../components/Sidebar/Sidebar";

const AdminLayout = () => {
  //TODO (should remove the testcode)

  const styleTest = {
    display: "flex",
  };
  const styleTest_1 = {
    flexGrow: 1,
  };

  return (
    <div style={styleTest}>
      <Sidebar />
      <div style={styleTest_1}>
        <Outlet />
      </div>
    </div>
  );
};

export default AdminLayout;
