import { Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FormErrorMessage } from "../../staticfiles/FormMessage";
import { useDispatch } from "react-redux";
import { handleCreateBranch } from "../../Redux/actions/RentlyPro";
import { useState } from "react";
import regx from "../../staticfiles/regex";

const AddNewBranchForm = (props) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm();

  const [isCreating, setIsCreating] = useState(false);
  const dispatch = useDispatch();
  const cb = () => props.onHide();

  // Watch the input fields
  const smsProductAmount = watch("smsProductAmount", "");
  const monthlyProductAmount = watch("monthlyProductAmount", "");

  // Helper function to handle float parsing and conversion
  const parseAndConvertValue = (value) => {
    return Math.round(parseFloat(value.replace(",", ".")) * 100) || 0;
  };

  // Function to handle field value change
  const handleChange = (field, value) => {
    // Regular expression to check if the value is numeric with up to two digits after the decimal point
    const isNumericWithDot = /^-?\d*\.?\d{0,2}$/;

    // If the user types a string and it's not allowed, or if it exceeds two digits after the decimal point, return
    if (!isNumericWithDot.test(value)) {
      return;
    }

    // Set the value in the form
    setValue(field, value);
  };

  // on submit handler for submitting the data
  const onSubmit = (data) => {
    const formattedData = {
      ...data,
      smsProductAmount: parseAndConvertValue(data.smsProductAmount),
      monthlyProductAmount: parseAndConvertValue(data.monthlyProductAmount),
    };

    // obj which has setIsCreating, and formattedData
    let obj = { setIsCreating, data: formattedData, cb };
    dispatch(handleCreateBranch(obj));
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group className="mb-3" controlId="formBasicInput">
        <Form.Control
          type="text"
          placeholder="Branch name"
          className="inputV3"
          {...register("name", {
            required: {
              value: true,
              message: FormErrorMessage.branchName,
            },
          })}
        />
        {errors?.name && (
          <p className="errorMsgV1 ms-3 visible">{errors.name?.message}</p>
        )}
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicInput">
        <Form.Control
          type="email"
          placeholder="Primary email"
          className="inputV3"
          {...register("pEmail", {
            required: {
              value: true,
              message: FormErrorMessage.email,
            },
            pattern: {
              value: regx.email,
              message: FormErrorMessage.emailFormat,
            },
          })}
        />
        {errors?.pEmail && (
          <p className="errorMsgV1 visible ms-3 mb-0 text-start">
            {errors.pEmail?.message}
          </p>
        )}
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicInput">
        <Form.Control
          type="text"
          placeholder="Region"
          className="inputV3"
          {...register("region", {
            required: {
              value: true,
              message: FormErrorMessage.region,
            },
          })}
        />
        {errors?.region && (
          <p className="errorMsgV1 ms-3 visible">{errors.region?.message}</p>
        )}
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicInput">
        <Form.Control
          type="text"
          placeholder="SMS Product Amount"
          className="inputV3"
          value={smsProductAmount}
          {...register("smsProductAmount", {
            required: {
              value: true,
              message: FormErrorMessage.smsProductAmount,
            },
            pattern: {
              value: /^[0-9]*\.?[0-9]+$/, // Regex to ensure valid float input
              message: FormErrorMessage.smsProductAmountFormat,
            },
            validate: (value) =>
              parseFloat(value) > 0 ||
              FormErrorMessage.smsProductAmountPositive,
          })}
          onChange={(e) => handleChange("smsProductAmount", e.target.value)}
        />
        {errors?.smsProductAmount && (
          <p className="errorMsgV1 ms-3 visible">
            {errors.smsProductAmount?.message}
          </p>
        )}
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicInput">
        <Form.Control
          type="text"
          placeholder="Monthly Product Amount"
          className="inputV3"
          value={monthlyProductAmount}
          {...register("monthlyProductAmount", {
            required: {
              value: true,
              message: FormErrorMessage.monthlyProductAmount,
            },
            pattern: {
              value: /^[0-9]*\.?[0-9]+$/, // Regex to ensure valid float input
              message: FormErrorMessage.monthlyProductAmountFormat,
            },
            validate: (value) =>
              parseFloat(value) > 0 ||
              FormErrorMessage.monthlyProductAmountPositive,
          })}
          onChange={(e) => handleChange("monthlyProductAmount", e.target.value)}
        />
        {errors?.monthlyProductAmount && (
          <p className="errorMsgV1 ms-3 visible">
            {errors.monthlyProductAmount?.message}
          </p>
        )}
      </Form.Group>

      <Button
        variant="primary"
        type="submit"
        className="w-100 fsr-14 fw-700"
        disabled={isCreating}
      >
        Add new branch
      </Button>
    </Form>
  );
};

export default AddNewBranchForm;
