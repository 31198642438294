import { Table as StyledTable } from "react-bootstrap";

const Table = ({ children }) => {
  return (
    <StyledTable striped bordered hover>
      {children}
    </StyledTable>
  );
};

const Header = ({ children }) => {
  return (
    <thead>
      <tr>{children}</tr>
    </thead>
  );
};
const Body = ({ data, render }) => {
  // if there is no data this will return no data available
  if (data === null || data?.length === 0)
    return (
      <tbody>
        <tr>
          <td colSpan="5">
            <p className="mb-0 text-center text-gray-v2">
              No data is available
            </p>
          </td>
        </tr>
      </tbody>
    );
  return <tbody>{data?.map(render)}</tbody>;
};

const Row = ({ children }) => {
  return <tr>{children}</tr>;
};

Table.Header = Header;
Table.Body = Body;
Table.Row = Row;

export default Table;
