import React, { useEffect, useState } from "react";
import Pagination from "../Pagination/Pagination";
import Table from "../Table/Table";
import Loader from "../Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { PAGE_SIZE } from "../../Config/config";
import { handleGetBranch } from "../../Redux/actions/RentlyPro";
import RPRow from "./RPRow";
import { useNavigate } from "react-router-dom";
const RPTable = ({
  isSearching,
  paginationNo,
  searchQuery,
  setPaginationNo,
  setSingleBranchData,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  // branch data
  const branchData = useSelector((store) => store.rentlyPro.rpData);
  // branch count
  const branchCount = useSelector((store) => store.rentlyPro.rpCount);
  const dispatch = useDispatch();
  useEffect(() => {
    let obj = {
      page: paginationNo,
      count: PAGE_SIZE,
      setIsLoading,
      searchQuery,
    };
    //when changing the current page this will dispatch and get the data
    dispatch(handleGetBranch(obj));
  }, [paginationNo]);

  const handleNavigate =(id)=>{
    navigate(`/rently-pro/branch/${id}`)
  }

  return (
    <>
      <div className="tableV1">
        <Table>
          <Table.Header>
            <th>
              <p className="ms-12px mb-0">Branch name</p>
            </th>
            <th>Primary contact</th>
            <th>Region</th>
            <th>Team members</th>
            <th colSpan={2}>Added</th>
          </Table.Header>
          {isLoading || isSearching || !branchData ? (
            <tbody>
              <tr>
                <td colSpan="5">
                  <Loader />
                </td>
              </tr>
            </tbody>
          ) : (
            <Table.Body
              data={branchData}
              render={(data) => (
                <RPRow
                  data={data}
                  key={data._id}
                  setSingleBranchData={setSingleBranchData}
                  handleNavigate={handleNavigate}
                />
              )}
            />
          )}
        </Table>
      </div>

      <Pagination
        count={branchCount}
        currentPage={paginationNo}
        setPaginationNo={setPaginationNo}
        loading={isLoading}
      />
    </>
  );
};

export default RPTable;
