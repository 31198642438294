import { createSlice } from "@reduxjs/toolkit";

const namespace = "admin";
const initialState = {
  vCompanyData: null,
  vCompanyCount: null,
  pCompanyData: null,
  pCompanyCount: null,
};

const getAllCompanySlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    setAllVCompany: (state, action) => {
      state.vCompanyData = action.payload;
    },
    setVCompanyCount: (state, action) => {
      state.vCompanyCount = action.payload;
    },
    setAddACompany: (state, action) => {
      state.vCompanyData = action.payload;
    },
    setAllPCompany: (state, action) => {
      state.pCompanyData = action.payload;
    },
    setPCompanyCount: (state, action) => {
      state.pCompanyCount = action.payload;
    },
    clearCompanyData: () => initialState,
  },
});

export const {
  setAllVCompany,
  setVCompanyCount,
  clearCompanyData,
  setAllPCompany,
  setPCompanyCount,
} = getAllCompanySlice.actions;
export default getAllCompanySlice.reducer;
