export const FormErrorMessage = {
  email: "Email cannot be empty",
  emailFormat: "Invalid email",
  password: "Password cannot be empty",
  passwordFormat: "Password must be at least 6 characters long",
  confirmPassword: "Confirm password cannot be empty",
  matchPassword: "Password is not matching",
  imageFormat: "Invalid image format",
  imageSizeUpto5mb: "Image size should be less than or equal to 5mb",
  url: "Url cannot be empty",
  urlFormat: "Invalid url",
  companyName: "Company name can't be empty",
  logo: "Please upload a company logo",
  prizeTitle: "Prize title cannot be empty",
  prizedDetail: "Prize details cannot be empty",
  prizeLogo: "Please upload prize logo",
  dealsCategory: "Category cannot be empty",
  dealImage: "Please upload deal image",
  branchName: "Branch name cannot be empty",
  region: "Region cannot be empty",
  option: "Please select the option",

  smsProductAmount: "SMS Product Amount is required",
  smsProductAmountFormat: "Invalid amount format",
  smsProductAmountPositive: "SMS Product Amount must be greater than zero",
  monthlyProductAmount: "Monthly Product Amount is required",
  monthlyProductAmountFormat: "Invalid amount format",
  monthlyProductAmountPositive:
    "Monthly Product Amount must be greater than zero",
};
