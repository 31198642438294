import { Button, Form, Modal } from "react-bootstrap";
import AddNewBranchForm from "./AddNewBranchForm";

const AddNewBranchModal = (props) => {
  return (
    <Modal
      {...props}
      size="md"
      dialogClassName="max-w-416px"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="addPrizeModal"
    >
      <Modal.Body className="p-3 py-md-40px">
        <AddNewBranchForm {...props} />
      </Modal.Body>
    </Modal>
  );
};

export default AddNewBranchModal;
