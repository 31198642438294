import { createAsyncThunk } from "@reduxjs/toolkit";
import toast, { Toaster } from "react-hot-toast";
import {
  capitalizeSentence,
  handleApiError,
} from "../../helper/helperFunction";
import { createBranchApi } from "../../API/RentlyPro/CreateBranchApi";
import {
  ChangeRole,
  getBranchApi,
  getSingleBranchApi,
  pauseSubscription,
  RemoveTeamMember,
  resumeSubscription,
} from "../../API/RentlyPro/GetBranchApi";
import {
  setBillInfo,
  setBillInfoCount,
  setBranchCount,
  setBranchUser,
  setRPCount,
  setRPData,
} from "../slices/rentlyProSlice";
import { deleteBranchApi } from "../../API/RentlyPro/DeleteBranchApi";
import { editBranchApi } from "../../API/RentlyPro/EditBranchApi";
import { getBranchUserApi } from "../../API/RentlyPro/GetBranchUserApi";
import { createBranchUserApi } from "../../API/RentlyPro/CreateBranchUserApi";
import { getBillingInfo } from "../../API/RentlyPro/BillingInfo";

export const handleGetBranch = createAsyncThunk(
  "admin/get-branch",
  async ({ page, searchQuery, count, setIsLoading, cb }, { dispatch }) => {
    try {
      // loading set to true
      setIsLoading(true);
      const getResp = await getBranchApi(page, count, searchQuery);
      if (getResp.success) {
        const branchData = getResp.data.BranchData;
        const branchCount = getResp.data.totalCount;
        // dispatching the created data and updated store
        dispatch(setRPData(branchData));
        // dispatching the created data count and updated store
        dispatch(setRPCount(branchCount));
        cb && cb();
      }
    } catch (error) {
      const message = handleApiError(error);
      toast.error(capitalizeSentence(message));
    } finally {
      setIsLoading(false);
    }
  }
);

/* ==================Create Branch ====================================== */
// Exporting the asynchronous thunk for handling branch creation
export const handleCreateBranch = createAsyncThunk(
  "admin/create-branch",
  async ({ data, setIsCreating, cb }, { dispatch, getState }) => {
    try {
      // loading set to true
      setIsCreating(true);
      // create api call with data
      const resp = await createBranchApi(data);
      // get branch api call
      const getResp = await getBranchApi();
      if (resp.success && getResp.success) {
        const branchData = getResp.data.BranchData;
        const branchCount = getResp.data.totalCount;
        // dispatching the created data and updated store
        dispatch(setRPData(branchData));
        // dispatching the created data count and updated store
        dispatch(setRPCount(branchCount));
        toast.success("Branch created successfully");
        cb && cb();
      }
    } catch (error) {
      const message = handleApiError(error);
      toast.error(capitalizeSentence(message));
    } finally {
      setIsCreating(false);
    }
  }
);

/* ==================Delete Branch ====================================== */
// Exporting the asynchronous thunk for handling branch deletion
export const handleDeleteBranch = createAsyncThunk(
  "admin/create-branch",
  async ({ id, setIsDeleting, cb }, { dispatch, getState }) => {
    try {
      // loading set to true
      setIsDeleting(true);
      // create api call with data
      const resp = await deleteBranchApi(id);
      if (resp.success) {
        toast.success("Branch deleted successfully");
        cb && cb();
      }
    } catch (error) {
      const message = handleApiError(error);
      toast.error(capitalizeSentence(message));
    } finally {
      setIsDeleting(false);
    }
  }
);

export const handleEditBranch = createAsyncThunk(
  "admin/EditBranch",
  async (
    { data, setIsLoading, cb, setSingleBranchData },
    { dispatch, getState }
  ) => {
    try {
      setIsLoading(true);
      const state = getState();

      const prevData = state.rentlyPro.rpData;
      const resp = await editBranchApi(data);
      if (resp.success) {
        const newData = resp.data;
        const updatedData = prevData.map((branch) =>
          newData._id === branch._id ? newData : branch
        );

        dispatch(setRPData(updatedData));
        setSingleBranchData && setSingleBranchData(newData);
        cb && cb();
        toast.success("Updated Successfully");
      }
    } catch (error) {
      const message = handleApiError(error);
      toast.error(capitalizeSentence(message));
    } finally {
      setIsLoading(false);
      cb();
    }
  }
);

export const handleGetBranchUser = createAsyncThunk(
  "admin/get-branchUser",
  async ({ id, page, searchQuery, count, setIsLoading, cb }, { dispatch }) => {
    try {
      setIsLoading(true);
      const getResp = await getBranchUserApi(id, page, count, searchQuery);
      if (getResp.success) {
        const branchUserData = getResp.data.invites;
        const branchUserCount = getResp.data.total;
        dispatch(setBranchUser(branchUserData));
        dispatch(setBranchCount(branchUserCount));
        cb && cb();
      }
    } catch (error) {
      const message = handleApiError(error);
      toast.error(capitalizeSentence(message));
    } finally {
      setIsLoading(false);
    }
  }
);

export const handleCreateBranchUser = createAsyncThunk(
  "admin/create-branchUser",
  async ({ id, data, setIsCreating, cb }, { dispatch, getState }) => {
    try {
      setIsCreating(true);
      const resp = await createBranchUserApi(data);
      const getResp = await getBranchUserApi(id);
      if (resp.success && getResp.success) {
        const branchUserData = getResp.data.invites;
        const branchUserCount = getResp.data.total;
        dispatch(setBranchUser(branchUserData));
        dispatch(setBranchCount(branchUserCount));
        toast.success(resp.message);
        cb && cb();
      }
    } catch (error) {
      const message = handleApiError(error);
      toast.error(capitalizeSentence(message));
    } finally {
      setIsCreating(false);
    }
  }
);
export const handleRemoveTeamMember = createAsyncThunk(
  "agent/removeMember",
  async ({ data, setIsLoading, cb }, { dispatch, getState }) => {
    try {
      setIsLoading(true);
      const teamData = getState().rentlyPro.branchUserData;
      let teamCount = getState().rentlyPro.branchUserCount;
      //Call api=> to get the team members
      const resp = await RemoveTeamMember(data);
      if (resp.success) {
        const updatedTeamData = teamData.filter(
          (member) => member?.user?._id !== data.userId
        );
        teamCount = teamCount - 1;

        dispatch(setBranchUser(updatedTeamData));
        dispatch(setBranchCount(teamCount));

        toast.success("Removed successfully");
        cb && cb();
      }
    } catch (error) {
      const message = handleApiError(error);
      toast.error(capitalizeSentence(message));
    } finally {
      setIsLoading(false);
    }
  }
);

export const handleChangeRole = createAsyncThunk(
  "agent/changeRole",
  async ({ data, setIsLoading, cb }, { dispatch, getState }) => {
    try {
      setIsLoading(true);
      const teamData = getState().rentlyPro.branchUserData;

      const resp = await ChangeRole(data);
      if (resp.success) {
        const { data: respData } = resp;
        const updatedTeamData = teamData.map((member) => {
          if (member?.user?._id === respData._id) {
            return { ...member, user: { ...member.user, role: respData.role } };
          } else {
            return member;
          }
        });
        dispatch(setBranchUser(updatedTeamData));

        toast.success("Updated successfully ");
        cb && cb();
      }
    } catch (error) {
      const message = handleApiError(error);
      toast.error(capitalizeSentence(message));
    } finally {
      setIsLoading(false);
    }
  }
);

export const handleGetSingleBranch = createAsyncThunk(
  "admin/get-single-branch",
  async ({ id, setSingleBranchData, setIsLoading }, { dispatch }) => {
    try {
      setIsLoading(true);
      const getResp = await getSingleBranchApi(id);
      if (getResp.success) {
        setSingleBranchData(getResp.data);
      }
    } catch (error) {
      const message = handleApiError(error);
      toast.error(capitalizeSentence(message));
    } finally {
      setIsLoading(false);
    }
  }
);

export const handleChangeSubStatus = createAsyncThunk(
  "admin/changeSub-status",
  async ({ data, type, setIsLoading }, { dispatch }) => {
    try {
      setIsLoading(true);

      if (type === "resume") {
        await resumeSubscription(data);
        toast.success("Successfully resume the subscription");
      } else {
        await pauseSubscription(data);
        toast.success("Successfully pause the subscription");
      }
    } catch (error) {
      const message = handleApiError(error);
      toast.error(capitalizeSentence(message));
    } finally {
      setIsLoading(false);
    }
  }
);

export const handleGetBillingInfo = createAsyncThunk(
  "admin/get-billingInfo",
  async (
    { data, page, searchQuery, count, setIsLoading, cb },
    { dispatch }
  ) => {
    try {
      setIsLoading(true);
      const getResp = await getBillingInfo({data, page, count, searchQuery});
      if (getResp.success) {
        const billingInfo = getResp.data.texts;
        const billingListCount = getResp.data.totalCount;
        dispatch(setBillInfoCount(billingListCount));
        dispatch(setBillInfo(billingInfo));
        cb && cb();
      }
    } catch (error) {
      const message = handleApiError(error);
      toast.error(capitalizeSentence(message));
    } finally {
      setIsLoading(false);
    }
  }
);
