import { asyncFunc } from "../common/asyncFun";
import { apiEndPoints } from "../common/endpoints";
const url = import.meta.env.VITE__APP_API;

export const Login = async (data) => {
  const config = {
    method: "post",
    url: url + apiEndPoints.Admin.login,
    data,
  };

  return await asyncFunc(config);
};

export const VerifyOtp = async (data) => {
  const config = {
    method: "post",
    url: url + apiEndPoints.Admin.verifyOtp,
    data,
  };

  return await asyncFunc(config);
};


export const ResendOtp = async (data) => {
    const config = {
      method: "post",
      url: url + apiEndPoints.Admin.resentOtp,
      data,
    };
  
    return await asyncFunc(config);
  };