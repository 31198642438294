import { getEnvPath } from "../../helper/helperFunction";
const ProfileUploadOperation = ({
  userData,
  handleFileChange,
  errors,
  loading,
}) => {
  return (
    <div className="position-relative text-center">
      <div className="imgWrapV2">
        <img
          src={getEnvPath("compressed-profilePic", userData?.profilePic)}
          alt="profile"
          className="roundedImgDefault"
          //which is offscreen image by the time user click the modal then the image will show
          loading="lazy"
        />
      </div>
      <input
        type="file"
        className="top-0 end-0 start-0 bottom-0 position-absolute opacity-0"
        onChange={handleFileChange}
        accept="image/*"
        disabled={loading}
      />
      {errors && (
        <div className="errorMsgV1 ms-3 visible">{errors?.image?.message}</div>
      )}
    </div>
  );
};

export default ProfileUploadOperation;
