import { Button, Form, Modal } from "react-bootstrap";
import AddPrizeForm from "./AddPrizeForm";

const AddPrizeModal = (props) => {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="addPrizeModal"
    >
      <Modal.Header closeButton className="p-8">
        <Modal.Title id="contained-modal-title-vcenter">
          <h2 className="fsr-16 fw-700 mb-0">Add new prize</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-8">
        <h3 className="fsr-16 fw-700 mb-3">Prize Details</h3>
        <AddPrizeForm onClose={() => props.onHide()} />
      </Modal.Body>
    </Modal>
  );
};

export default AddPrizeModal;
