import React, { useState } from "react";
import { Button, Dropdown } from "react-bootstrap";
import Search from "../Search/Search";
import AddNewBranchModal from "./AddNewBranchModal";

const RPHeaderOperation = ({
  setShowAddBranchModal,
  showAddBranchModal,
  setSearchQuery,
  searchQuery,
}) => {
  return (
    <>
      <div className="d-flex mb-4">
        <Search
          customStyles={"me-3"}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
        />
         {/* commenting this for now=> client dont want this now */}
        {/* <Dropdown className="dropdownV2">
          <Dropdown.Toggle
            variant="outline-primary"
            className=" d-flex justify-content-between align-items-center"
            id="dropdown-basic"
          >
            Filter
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item href="#/action-1">All</Dropdown.Item>
            <Dropdown.Item href="#/action-2">Active</Dropdown.Item>
            <Dropdown.Item href="#/action-3">Inactive</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> */}

        <Button
          className="min-w-150px px-28px d-flex align-items-center justify-content-center ms-auto"
          variant="primary"
          onClick={() => setShowAddBranchModal(true)}
        >
          Add new branch
        </Button>
      </div>
      <AddNewBranchModal
        show={showAddBranchModal}
        onHide={() => setShowAddBranchModal(false)}
      />
    </>
  );
};

export default RPHeaderOperation;
