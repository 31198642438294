import { createSlice } from "@reduxjs/toolkit";

const namespace = "admin";
const initialState = {
  graphData: null,
};

const getGraphSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    setGraph: (state, action) => {
      state.graphData = action.payload;
    },

    clearGraphData: () => initialState,
  },
});

export const { setGraph, clearGraphData } = getGraphSlice.actions;
export default getGraphSlice.reducer;
