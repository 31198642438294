import { Modal } from "react-bootstrap";
import { useState } from "react";
import Loader from "../Loader/Loader";

const HelpModal = (props) => {
  const [videoLoaded, setVideoLoaded] = useState(false);

  const handleVideoLoad = () => {
    setVideoLoaded(true);
  };

  const handleVideoError = () => {
    setVideoLoaded(true);
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="py-md-32px px-md-5">
        {!videoLoaded && <Loader />}
        <div
          style={{
            display: videoLoaded ? "block" : "none",
            position: "relative",
            paddingBottom: "56.25%",
            height: 0,
          }}
        >
          <iframe
            src="https://www.loom.com/embed/6b584805fc034ea3abe57a79e09f8882?sid=7ceb0f31-f21f-4f61-9c9d-a8770bb2de6f"
            webkitallowfullscreen
            mozallowfullscreen
            allowFullScreen
            style={{
              display: videoLoaded ? "block" : "none",
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
            onLoad={handleVideoLoad}
            onError={handleVideoError}
          ></iframe>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default HelpModal;
