import { asyncFunc } from "../common/asyncFun";
import { apiEndPoints } from "../common/endpoints";
import { setHeaders } from "../common/setHeader";
const url = import.meta.env.VITE__APP_API;

export const getPrizes = async (page, count, search,) => {
    const fullUrl = `${url}${apiEndPoints.Admin.getPrize}?${page !== undefined ? `pgNo=${page}&count=${count}` : ''}${search ? `&search=${search}` : ''}`;

  const config = {
    method: "get",
    url: fullUrl,
    headers: setHeaders("application/json", true),
  };
     
  return await asyncFunc(config);
};
