import { createSlice } from "@reduxjs/toolkit";

const namespace = "admin";
const initialState = {
  branchUserCount: null,
  branchU: null,
  rpData: null,
  rpCount: null,
  billingInfo:null,
  billingInfoCount:null
};

const rentlyProSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    setRPData: (state, action) => {
      state.rpData = action.payload;
    },
    setRPCount: (state, action) => {
      state.rpCount = action.payload;
    },
    setBranchUser: (state,action) => {
      state.branchUserData = action.payload;
    },
    setBranchCount: (state, action) => {
      state.branchUserCount = action.payload;
    },
    setBillInfoCount: (state,action) => {
      state.billingInfoCount = action.payload;
    },
    setBillInfo: (state, action) => {
      state.billingInfo = action.payload;
    },
    clearRPData: () => initialState,
  },
});

export const {
  setRPData,
  clearRPData,
  setRPCount,
  setBranchUser,
  setBranchCount,
  setBillInfoCount,
  setBillInfo
} = rentlyProSlice.actions;
export default rentlyProSlice.reducer;
