import store from "../../Redux/store";

export const setHeaders = (contentType, token) => {
  const userData = store.getState().adminAuth.userData;
  let headers = {
    "Content-Type": contentType,
    "X-Timezone-Offset": new Date().getTimezoneOffset(),
    "Timezone-Central": Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  if (token) {
    headers.Authorization = `Bearer ${userData.jwtToken}`;
  }
  return headers;
};
