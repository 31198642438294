import { Button, Form, Modal } from "react-bootstrap";
import MailOperation from "./MailOperation";
import { EDIT_DETAILS } from "../../Config/config";
import { useState } from "react";
import { useForm } from "react-hook-form";
import PasswordOperation from "./PasswordOperation";
import { logOutUser } from "../../Redux/actions/adminAuth";
import { useDispatch, useSelector } from "react-redux";
import {
  handleUpdatePassword,
  handleCredentialsChange,
  uploadAdminProfile,
} from "../../Redux/actions/adminAuth";
import ProfileUploadOperation from "./ProfileUploadOperation";
import { ImageValidation, IMAGE_FORMAT_ALLOWED } from "../../Config/config";
import { FormErrorMessage } from "../../staticfiles/FormMessage";
import DeleteModal from "./DeleteModal";
import { useSearchParams } from "react-router-dom";
const EditProfileModal = (props) => {
  const userData = useSelector((store) => store.adminAuth.userData);
  const [loading, setLoading] = useState(false);
  const [showEdit, setEditShow] = useState(EDIT_DETAILS);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useDispatch();
  const {
    handleSubmit: handleSubmitGmail,
    register: registerGmail,
    formState: { errors: errorsGmail },
  } = useForm();
  const {
    handleSubmit: handleSubmitPassword,
    register: registerPassword,
    formState: { errors: errorsPassword },
    watch,
  } = useForm();
  const { errors, clearErrors, setError } = useForm();
  const cb = () => {
    props?.onHide();
  };
  let obj = { setLoading, cb, setEditShow, EDIT_DETAILS };

  const onSubmitEditGmail = (data) => {
    //dispatching the email
    if (data?.email === undefined) {
      props.onHide();
      //set to previous state
      setEditShow(EDIT_DETAILS);
      return;
    }
    obj = { ...obj, data };
    dispatch(handleCredentialsChange(obj));
  };
  const handleLogout = () => dispatch(logOutUser());
  const onSubmitEditPassword = ({ confirmPassword, ...data }) => {
    setSearchParams("page", "");
    obj = { ...obj, data };
    dispatch(handleUpdatePassword(obj));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    // image validation=> preventing from submitting other than image files
    const isValid = ImageValidation(file, IMAGE_FORMAT_ALLOWED);
    if (!isValid)
      return customFormError(setError, "image", FormErrorMessage?.imageFormat);

    let obj = { file, setLoading, cb };
    //which will clear the error in that specific field and dispatch the file
    clearErrors("image");
    dispatch(uploadAdminProfile(obj));
  };
  const handleDeleteClickPopUp = () => {
    setShowDeleteModal(true);
  };
  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <Form>
            <div className="py-3 d-flex flex-column gap-3">
              <div className="d-flex gap-3 align-items-center mb-4">
                <ProfileUploadOperation
                  handleFileChange={handleFileChange}
                  errors={errors}
                  loading={loading}
                  userData={userData}
                />

                <div>
                  <p className="mb-0 fsr-24 fw-700">Joshua Pavis</p>
                </div>
              </div>

              <MailOperation
                registerGmail={registerGmail}
                errorsGmail={errorsGmail}
                handleSubmitGmail={handleSubmitGmail}
                loading={loading}
                onSubmitEditGmail={onSubmitEditGmail}
                setEditShow={setEditShow}
                userData={userData}
                showEdit={showEdit}
              />

              <PasswordOperation
                onSubmitEditPassword={onSubmitEditPassword}
                errorsPassword={errorsPassword}
                handleSubmitPassword={handleSubmitPassword}
                registerPassword={registerPassword}
                watch={watch}
                showEdit={showEdit}
                setEditShow={setEditShow}
                loading={loading}
              />

              {/* <div className="text-end">
              <a
                href="#"
                className="text-gray-v2 fsr-14 fw-500"
                onClick={handleLogout}
              >
                Logout
              </a>
            </div> */}
              <div className="d-flex justify-content-between">
                <div className="text-center">
                  <a
                    href="#"
                    className="text-gray-v2 fsr-14 fw-500 deleteBtn"
                    onClick={handleDeleteClickPopUp}
                  >
                    Delete your account forever
                  </a>
                </div>
                <div className="text-center" onClick={handleLogout}>
                  <a className="text-gray-v2 fsr-14 fw-500 cursor-pointer hoverBtn">
                    Logout
                  </a>
                </div>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      <DeleteModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        // onClickDelete={onClickDelete}
      />
    </>
  );
};

export default EditProfileModal;
