import React from "react";
import Prizes from "../components/Prizes";
import RentlyPro from "../components/RentlyPro";
const TenantHistory = React.lazy(() =>
  import("../components/tenanatHistory/TenantHistory")
);
const TenantProfileDetails = React.lazy(() =>
  import("../components/PreviewModal/TenantProfileDetails")
);
const HouseHoldDetail = React.lazy(() =>
  import("../components/PreviewModal/HouseHoldProfileDetail")
);

//lazy importing
const Dashboard = React.lazy(() => import("../components/Dashboard/Dashboard"));
const Profile = React.lazy(() => import("../components/Profiles/Profiles"));
const Verified = React.lazy(() => import("../components/Verified/Verified"));
const Advertisement = React.lazy(() =>
  import("../components/Advertisements/Advertisements")
);
const TenantQuestion = React.lazy(() =>
  import("../components/TenantQuestions/TentQuestions")
);
const Login = React.lazy(() =>
  import("../components/Authentication/Login/Login")
);
const ForgetPassword = React.lazy(() =>
  import("../components/Authentication/Login/ForgetPassword")
);
const ResetPassword = React.lazy(() =>
  import("../components/Authentication/Login/ResetPassword")
);
const Deals = React.lazy(() => import("../components/Deals/Deals"));
const LeadDetails = React.lazy(() =>
  import("../components/Lead/LeadDetails/Lead")
);
const MFA = React.lazy(() => import("../components/Authentication/MFA/"));

const BranchDetails = React.lazy(() =>
  import("../components/RentlyPro/BranchDetails")
);

const Clicks = React.lazy(() => import("../components/Clicks/Clicks"));
export const LoginRoutes = [
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/forget-password",
    component: ForgetPassword,
  },
  {
    path: "/reset-password",
    component: ResetPassword,
  },
  {
    path: "/mfa",
    component: MFA,
  },
];

export const authRoutes = [
  {
    path: "/dashboard",
    component: Dashboard,
  },
  {
    path: "/history",
    component: TenantHistory,
  },

  {
    path: "/profile",
    component: Profile,
  },
  {
    path: "/verified-agent",
    component: Verified,
  },
  {
    path: "/advertisement",
    component: Advertisement,
  },
  {
    path: "/tenant-questions",
    component: TenantQuestion,
  },
  {
    path: "/prizes",
    component: Prizes,
  },
  {
    path: "/rently-pro",
    component: RentlyPro,
  },
  {
    path: "/rently-pro/branch/:id",
    component: BranchDetails,
  },
  {
    path: "/rently-deals",
    component: Deals,
  },
  {
    path: "/clicks",
    component: Clicks,
  },
];

export const UserRoutes = [
  {
    path: "/tenant/:id",
    component: TenantProfileDetails,
  },
  {
    path: "/lead/:id",
    component: LeadDetails,
  },
  {
    path: "/households/:id",
    component: HouseHoldDetail,
  },
  {
    path: "/household/:id",
    component: TenantProfileDetails,
  },
];
