import moment from "moment";
import Table from "../Table/Table";
import Button from "react-bootstrap/Button";
const RPRow = ({ data, setSingleBranchData , handleNavigate}) => {
  return (
    <>
      <Table.Row>
        <td>
          <p className="ms-12px mb-0 fw-700">{data.name}</p>
        </td>
        <td>
          <p className="fw-500 mb-0 textBlackV1">{data?.primaryContact}</p>
        </td>
        <td>
          <p className="fw-500 mb-0">{data.region}</p>
        </td>
        <td>
          <p className="fw-500 mb-0">{data.teamCount ?? 0}</p>
        </td>
        <td>
          <p className="fw-500 mb-0">
            {" "}
            {moment(data?.createdAt).format("DD-MM-YYYY")}
          </p>
        </td>
        <td>
          <div className="pe-1">
            <Button
              variant="secondary"
              className="rounded-btn-v1 p-0 ms-auto"
              onClick={() => handleNavigate(data._id)}
              
            >
              <img src="/img/threeDotIcon.svg" alt="..." />
            </Button>
          </div>
        </td>
      </Table.Row>
    </>
  );
};

export default RPRow;
