import React from "react";
import { Button } from "react-bootstrap";
import { PlusIcon } from "../../staticfiles/svgPack";
import Search from "../Search/Search";

const PrizesHeaderOperation = ({
  onClickAddPrize,
  searchQuery,
  setSearchQuery,
}) => {
  return (
    <div className="d-flex mb-4">
      <Button
        className="min-w-150px px-28px d-flex align-items-center justify-content-center"
        variant="primary"
        onClick={onClickAddPrize}
      >
        <PlusIcon />
        <span className="ms-2">Add Prize</span>
      </Button>

      <Search
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        customStyles={"ms-auto"}
      />
    </div>
  );
};

export default PrizesHeaderOperation;
