import { Form, InputGroup } from "react-bootstrap";
const Search = ({ searchQuery, setSearchQuery, customStyles }) => {
  return (
    <div className={`inputGroupV1 ${customStyles}`}>
      <InputGroup className="h-100">
        <InputGroup.Text id="basic-addon1">
          <img src="/img/searchIcon.svg" alt="search" />
        </InputGroup.Text>
        <Form.Control
          placeholder="Search"
          aria-label="Search"
          aria-describedby="basic-addon1"
          onChange={(e) => setSearchQuery(e.target.value)}
          value={searchQuery}
          name="searchValue"
        />
      </InputGroup>
    </div>
  );
};

export default Search;
