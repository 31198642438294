import { Button, Modal } from "react-bootstrap";
const DeleteModal = ({ onClickDelete, loading, ...props }) => {
  const onClickCancel = () => {
    props.onHide();
  };
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="deleteModal"
    >
      <Modal.Body className="py-md-40px px-md-40px">
        <div className="text-center mb-28">
          <div className="text-center mb-3">
            <img src="/img/cross-icon.svg" alt="cross-icon" />
          </div>
          <h3 className="text-gray fsr-24 fw-700">
            Are you sure you want to delete?
          </h3>
          <p className="text-gray fsr-14 fw-400">
            This action can not be undone.
          </p>
        </div>

        <div className="d-flex flex-wrap gap-3 fsr-14 fw-500 justify-content-center align-items-center">
          <Button variant="primary" onClick={onClickDelete} disabled={loading}>
            Yes
          </Button>
          <Button
            variant="outline-primary"
            onClick={() => onClickCancel()}
            disabled={loading}
          >
            No
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteModal;
