import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useState } from "react";
import ReplacePrize from "./ReplacePrize";
import DeleteModal from "../Modals/DeleteModal";
import { formatTimestamp, getEnvPath } from "../../helper/helperFunction";
import { EDIT_PRIZE_ACTIVE } from "../../Config/config";
import { handleDeletePrize, handleEditPrize } from "../../Redux/actions/prizes";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
const ViewPrizeModal = (props) => {
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [edit, setEdit] = useState(EDIT_PRIZE_ACTIVE);
  const [editTitle, setEditTitle] = useState(props.data.title);
  const [searchParams, setSearchParams] = useSearchParams();
  const [editDetails, setEditDetails] = useState(props.data.details);
  const dispatch = useDispatch();
  // id of the prize-
  const id = props.data._id;
  const handleSetNameEdit = () => {
    setEdit({ ...edit, title: true });
  };
  const cb = () => {
    // hide the modal
    props.onHide();
    // set to initial prize state
    setEdit(EDIT_PRIZE_ACTIVE);
  };

  const onClickDelete = () => {
    setSearchParams("page", "");
    let obj = { id, cb, setLoading };
    //dispatching the delete prize
    dispatch(handleDeletePrize(obj));
  };

  const handleClickEdit = (value) => {
    let obj = {
      data: { title: value, prizeId: id },
      setLoading,
      cb,
    };
    //dispatching the edit prize
    dispatch(handleEditPrize(obj));
  };

  const handleClickEditDetails = (value) => {
    let obj = {
      data: { details: value, prizeId: id },
      setLoading,
      cb,
    };
    //dispatching the edit prize
    dispatch(handleEditPrize(obj));
  };
  return (
    <>
      <Modal
        {...props}
        dialogClassName="viewProfileModal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className=""
      >
        <Modal.Header closeButton className="p-8">
          <Modal.Title id="contained-modal-title-vcenter">
            <h2 className="fsr-16 fw-700 mb-0">View Prize</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-8">
          <Row className="mb-3">
            <Col xs={7}>
              <p className="mb-0 fsr-16 fw-700">Details</p>
            </Col>
            <Col xs={5}>
              <p className="mb-0 ms-auto text-gray-v2 fsr-16 fw-700 text-end">
                Created: {formatTimestamp(props.data.createdAt)}
              </p>
            </Col>
          </Row>

          <Row className="">
            <Col xs={5}>
              <p className="mb-3 fsr-14 fw-700 text-gray-v2">Name</p>
              <p className="fsr-14 fw-700 text-gray-v2">Details</p>
            </Col>
            <Col xs={7}>
              <div className="d-flex justify-content-between mb-3">
                {!edit.title ? (
                  <>
                    <p className="mb-0 fsr-14 fw-600">{props.data.title}</p>
                    <span
                      role="button"
                      className="mb-0 fsr-14 text-gray-v2 fw-500 text-decoration-underline hoverBtn"
                      onClick={handleSetNameEdit}
                    >
                      Edit
                    </span>
                  </>
                ) : (
                  <>
                    <input
                      type="text"
                      value={editTitle}
                      name="title"
                      onChange={(e) => setEditTitle(e.target.value)}
                      className="border-secondary rounded border outline-0 submitInput"
                      disabled={loading}
                    />
                    <button
                      onClick={() => handleClickEdit(editTitle)}
                      disabled={loading}
                      className="mb-0 fsr-14 text-gray-v2 fw-500 text-decoration-underline hoverBtn border-0 bg-white px-0 py-0"
                    >
                      Submit
                    </button>
                  </>
                )}
              </div>

              <div className="d-flex justify-content-between mb-3">
                {!edit.details ? (
                  <>
                    <p className="mb-0 fsr-14 fw-600">{props.data.details}</p>
                    <span
                      role="button"
                      className="mb-0 fsr-14 text-gray-v2 fw-500 text-decoration-underline hoverBtn"
                      onClick={() => setEdit({ ...edit, details: true })}
                    >
                      Edit
                    </span>
                  </>
                ) : (
                  <>
                    <input
                      type="text"
                      value={editDetails}
                      name="title"
                      onChange={(e) => setEditDetails(e.target.value)}
                      className="border-secondary rounded border outline-0 submitInput"
                      disabled={loading}
                    />
                    <button
                      onClick={() => handleClickEditDetails(editDetails)}
                      disabled={loading}
                      className="mb-0 fsr-14 text-gray-v2 fw-500 text-decoration-underline hoverBtn border-0 bg-white px-0 py-0"
                    >
                      Submit
                    </button>
                  </>
                )}
              </div>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col xs={5} className="d-flex align-items-center">
              <p className="fsr-14 fw-700 text-gray-v2 mb-0">Prize Logo</p>
            </Col>
            <Col xs={7}>
              <div className="d-flex justify-content-between">
                {props.data?.logo ? (
                  <div className="imgWrapV3">
                    <img
                      src={getEnvPath("profilePic", props.data.logo)}
                      alt="prizeLogo"
                    />
                  </div>
                ) : (
                  <div className="imgWrapV3">
                    <img src="/img/viewPrizeSampleImg.svg" alt="companyLogo" />
                  </div>
                )}
                <ReplacePrize id={id} setLoading={setLoading} cb={cb} />
              </div>
            </Col>
          </Row>

          <div
            className="text-center"
            onClick={loading ? null : () => setShowDeleteModal(true)}
            role="button"
          >
            <p className="text-gray-v2 fsr-14 fw-500 deleteBtn text-decoration-underline">
              Delete this Prize forever
            </p>
          </div>
        </Modal.Body>
      </Modal>

      <DeleteModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        onClickDelete={onClickDelete}
        loading={loading}
      />
    </>
  );
};

export default ViewPrizeModal;
