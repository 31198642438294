import React, { useState, useEffect } from "react";
import PrizesHeaderOperation from "./PrizesHeaderOperation";
import AddPrizeModal from "../Modals/AddPrizeModal";
import PrizesTable from "./PrizesTable";
import { useDispatch } from "react-redux";
import useDebounce from "../../hooks/useDebounce";
import { handleGetPrize } from "../../Redux/actions/prizes";
const PrizesDashboard = () => {
  const [showAddPrize, setShowAddPrize] = useState(false);
  const [searching, setSearching] = useState(false);
  const dispatch = useDispatch();
  const [paginationNo, setPaginationNo] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearch = useDebounce(searchQuery, 600);

  useEffect(() => {
    setPaginationNo(1);
    let obj = {
      searchQuery,
      setLoading: setSearching,
    };

    dispatch(handleGetPrize(obj));
  }, [debouncedSearch]);
  return (
    <>
      <div className="content-body p-8">
        <PrizesHeaderOperation
          onClickAddPrize={() => setShowAddPrize(true)}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
        />
        <PrizesTable
          searching={searching}
          paginationNo={paginationNo}
          setPaginationNo={setPaginationNo}
          searchQuery={searchQuery}
        />
      </div>
      <AddPrizeModal
        show={showAddPrize}
        onHide={() => setShowAddPrize(false)}
      />
    </>
  );
};

export default PrizesDashboard;
