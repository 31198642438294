import { asyncFunc } from "../common/asyncFun";
import { apiEndPoints } from "../common/endpoints";
import { setHeaders } from "../common/setHeader";
const url = import.meta.env.VITE__APP_API;

export const getBranchApi = async (page, count, search) => {
  const fullUrl = `${url}${apiEndPoints.Admin.getBranch}?${
    page !== undefined ? `pgNo=${page}&count=${count}` : ""
  }${search ? `&search=${search}` : ""}`;

  const config = {
    method: "get",
    url: fullUrl,
    headers: setHeaders("application/json", true),
  };

  return await asyncFunc(config);
};

export const RemoveTeamMember = async (data) => {
  const fullUrl = `${url}${apiEndPoints.Admin.removeTeamMember}`;
  const config = {
    method: "put",
    url: fullUrl,
    headers: setHeaders("application/json", true),
    data,
  };

  return await asyncFunc(config);
};

export const ChangeRole = async (data) => {
  const fullUrl = `${url}${apiEndPoints.Admin.updateRole}`;
  const config = {
    method: "put",
    url: fullUrl,
    headers: setHeaders("application/json", true),
    data,
  };

  return await asyncFunc(config);
};

export const getSingleBranchApi = async (id) => {
  const fullUrl = `${url}${apiEndPoints.Admin.getSingletBranch.replace(
    ":id",
    id
  )}`;
  const config = {
    method: "get",
    url: fullUrl,
    headers: setHeaders("application/json", true),
  };

  return await asyncFunc(config);
};


export const pauseSubscription = async (data) => {
  const fullUrl = `${url}${apiEndPoints.Admin.pauseSubscription}`;
  const config = {
    method: "post",
    url: fullUrl,
    headers: setHeaders("application/json", true),
    data
  };

  return await asyncFunc(config);
};

export const resumeSubscription = async (data) => {
  const fullUrl = `${url}${apiEndPoints.Admin.resumeSubscription}`;
  const config = {
    method: "post",
    url: fullUrl,
    headers: setHeaders("application/json", true),
    data
  };

  return await asyncFunc(config);
};