import { Button, Form } from "react-bootstrap";
const InputEditElement = ({
  id,
  type,
  placeholder,
  label,
  className,
  name,
  register,
  errors,
  validationSchema = {},
  ...rest
}) => {
  return (
    <>
      <Form.Group controlId="exampleForm.ControlInput1">
        <Form.Control
          id={id}
          type={type}
          placeholder={placeholder}
          {...(register && name && { ...register(name, validationSchema) })}
          {...rest}
        />
        {label && <label htmlFor={id}>{label}</label>}
      </Form.Group>
      {errors && (
        <div className="errorMsgV1 ms-3 visible">{errors?.message}</div>
      )}
    </>
  );
};

export default InputEditElement;
