import React, { useEffect, useState } from "react";
import Table from "../Table/Table";
import { handleGetPrize } from "../../Redux/actions/prizes";
import { useSelector, useDispatch } from "react-redux";
import Pagination from "../Pagination/Pagination";
import { PAGE_SIZE } from "../../Config/config";
import PrizeRow from "./PrizeRow";
import Loader from "../Loader/Loader";
const PrizesTable = ({
  searching,
  searchQuery,
  setPaginationNo,
  paginationNo,
}) => {
  const [loading, setLoading] = useState(false);
  const prizeData = useSelector((store) => store.prize);
  const dispatch = useDispatch();

  useEffect(() => {
    let obj = { page: paginationNo, count: PAGE_SIZE, setLoading, searchQuery };
    //when changing the current page this will dispatch and get the data
    dispatch(handleGetPrize(obj));
  }, [paginationNo]);

  return (
    <>
      <div className="tableV1">
        <Table>
          <Table.Header>
            <th>
              <p className="ms-12px mb-0">Prize Logo</p>
            </th>
            <th>Title</th>
            <th>Details</th>
            <th colSpan={2}></th>
          </Table.Header>
          {loading || searching ? (
            <tbody>
              <tr>
                <td colSpan="5">
                  <Loader />
                </td>
              </tr>
            </tbody>
          ) : (
            <Table.Body
              data={prizeData.prizeData}
              render={(data) => <PrizeRow data={data} key={data._id} />}
            />
          )}
        </Table>
      </div>
      <Pagination
        count={prizeData.prizeCount}
        currentPage={paginationNo}
        setPaginationNo={setPaginationNo}
        loading={loading}
      />
    </>
  );
};

export default PrizesTable;
