import { asyncFunc } from "../common/asyncFun";
import { apiEndPoints } from "../common/endpoints";
import { setHeaders } from "../common/setHeader";
const url = import.meta.env.VITE__APP_API;
export const getBillingInfo = async ({data,page,count,search}) => {
  const fullUrl = `${url}${apiEndPoints.Admin.billingInfo}?${
    page !== undefined ? `page=${page}&limit=${count}` : ""
  }${search ? `&search=${search}` : ""}`;
  const config = {
    method: "post",
    url: fullUrl,
    headers: setHeaders("application/json", true),
    data
  };

  return await asyncFunc(config);
};
