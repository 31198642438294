import { useEffect, useState } from "react";
import RPHeaderOperation from "./RPHeaderOperation";
import RPTable from "./RPTable";
import { useDispatch } from "react-redux";
import useDebounce from "../../hooks/useDebounce";
import { handleGetBranch } from "../../Redux/actions/RentlyPro";
const RPDashboard = ({ setSingleBranchData}) => {
  const [showAddBranchModal, setShowAddBranchModal] = useState(false);
  const [searching, setSearching] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);
  const [paginationNo, setPaginationNo] = useState(1);
  const [searchQuery, setSearchQuery] = useState(null);
  const debouncedSearch = useDebounce(searchQuery, 600);
  const dispatch = useDispatch();

  useEffect(() => {
    // if the search query is null
    if (searchQuery !== null) {
      setPaginationNo(1);
      let obj = {
        searchQuery,
        setIsLoading: setSearching,
      };
      // dispatching the action to get branch data
      dispatch(handleGetBranch(obj));
    }
  }, [debouncedSearch]);
  return (
    <>
      <div className="content-body p-8">
        <RPHeaderOperation
          setShowAddBranchModal={setShowAddBranchModal}
          showAddBranchModal={showAddBranchModal}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
        />
        <RPTable
          isSearching={searching}
          paginationNo={paginationNo}
          searchQuery={searchQuery}
          setPaginationNo={setPaginationNo}
          setSingleBranchData={setSingleBranchData}   
        />
      </div>
    </>
  );
};

export default RPDashboard;
