import { useRef, useState } from "react";
import DeleteModal from "../Modals/DeleteModal";
import { useDispatch } from "react-redux";
import { handleEditPrize } from "../../Redux/actions/prizes";

const ReplacePrize = ({ id, setLoading, cb }) => {
  // refer to input field
  const fileDesktop = useRef(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleReplaceClick = () => fileDesktop.current?.click();
  const dispatch = useDispatch();

  const fileChange = (e) => {
    const file = e.target.files;
    const formData = new FormData();
    formData.append("prizeId", id);
    formData.append("prizelogo", file[0]);
    let data = formData;
    let obj = { data, setLoading, cb, upload: true };

    // dispatching the handle prize
    dispatch(handleEditPrize(obj));
  };
  return (
    <>
      <div className="d-flex flex-column gap-2 justify-content-center">
        <span
          role="button"
          className="mb-0 fsr-14 text-gray-v2 fw-500 text-decoration-underline hoverBtn"
          onClick={handleReplaceClick}
        >
          Replace
        </span>
        <input
          type="file"
          style={{ display: "none", visibility: "hidden" }}
          ref={fileDesktop}
          onChange={(e) => fileChange(e)}
        />
        {/* <span
          role="button"
          className="mb-0 fsr-14 text-gray-v2 fw-500 text-decoration-underline hoverBtn"
        >
          Remove
        </span> */}
      </div>
      <DeleteModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
      />
    </>
  );
};

export default ReplacePrize;
