import { createSlice } from "@reduxjs/toolkit";

const namespace = "admin";
const initialState = {
  prizeData: null,
  prizeCount: null,
};

const getPrizeSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    setPrize: (state, action) => {
      state.prizeData = action.payload;
    },
    setPrizeCount: (state, action) => {
      state.prizeCount = action.payload;
    },

    clearPrizeData: () => initialState,
  },
});

export const { setPrize, clearPrizeData, setPrizeCount } =
  getPrizeSlice.actions;
export default getPrizeSlice.reducer;
