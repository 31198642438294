import { configureStore } from "@reduxjs/toolkit";
import adminSlice from "./slices/adminSlice.jsx";
import { combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer, PERSIST } from "redux-persist";
import storage from "redux-persist/lib/storage";
import getAllUserSlice from "./slices/getAllUserSlice.jsx";
import getAllCompanySlice from "./slices/getAllCompanySlice.jsx";
import adSlice from "./slices/advertisementSlice.jsx";
import graphSlice from "./slices/graphSlice.jsx";
import getPrizeSlice from "./slices/getAllPrizes.jsx";
import getDealsSlice from "./slices/getAllDeals.jsx";
import rentlyProSlice from "./slices/rentlyProSlice.jsx";
import leadSlice from "./slices/leadSlice.jsx";
const persistConfig = {
  key: "root",
  storage,
};
const reducer = combineReducers({
  adminAuth: adminSlice,
  getUsers: getAllUserSlice,
  getVCompany: getAllCompanySlice,
  advertisement: adSlice,
  graph: graphSlice,
  prize: getPrizeSlice,
  deals: getDealsSlice,
  rentlyPro: rentlyProSlice,
  leads: leadSlice,
});
const persistedReducer = persistReducer(persistConfig, reducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [PERSIST],
      },
    }),
});
export const persistor = persistStore(store);
export default store;
