import InputEditElement from "./InputElem";
import { Button } from "react-bootstrap";
import { FormErrorMessage } from "../../staticfiles/FormMessage";
import regex from "../../staticfiles/regex";

const MailOperation = ({
  userData,
  showEdit,
  errorsGmail,
  handleSubmitGmail,
  onSubmitEditGmail,
  setEditShow,
  loading,
  registerGmail,
}) => {
  return (
    <div className="position-relative inputV1">
      <InputEditElement
        type="email"
        placeholder="email"
        className="h-100"
        defaultValue={userData?.email}
        disabled={!showEdit.isEditGmail}
        register={registerGmail}
        validationSchema={{
          pattern: {
            value: regex.email,
            message: FormErrorMessage?.emailFormat,
          },
        }}
        name={"email"}
        errors={errorsGmail?.email}
      />
      <Button
        variant="dark"
        className="position-absolute end-0 top-0 h-100 fw-700 px-3 min-w-100px"
        onClick={
          showEdit.isEditGmail
            ? handleSubmitGmail(onSubmitEditGmail)
            : () =>
                setEditShow({
                  ...showEdit,
                  isEditGmail: !showEdit.isEditGmail,
                })
        }
        disabled={loading}
      >
        {showEdit.isEditGmail ? "Submit" : "Edit"}
      </Button>
    </div>
  );
};

export default MailOperation;
