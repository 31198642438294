import { createSlice } from "@reduxjs/toolkit";

const namespace = "admin";
const initialState = {
  Advertisements: null,
};

const adSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    setAdvertisement: (state, action) => {
      state.Advertisements = action.payload;
    },
    // setAuth: (state, action) => {
    //   state.isLogged = action.payload;
    // },
    clearAdData: () => initialState,
  },
});

export const { setAdvertisement, clearAdData } = adSlice.actions;
export default adSlice.reducer;
