import { createSlice } from "@reduxjs/toolkit";

const namespace = "admin";
const initialState = {
  userData: {},
  isLogged: false,
};

const adminSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    setAdmin: (state, action) => {
      state.userData = action.payload;
    },
    setAuth: (state, action) => {
      state.isLogged = action.payload;
    },
    clearAuth: () => initialState,
  },
});

export const { setAdmin, clearAuth, setAuth } = adminSlice.actions;
export default adminSlice.reducer;
