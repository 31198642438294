import { createAsyncThunk } from "@reduxjs/toolkit";
import toast, { Toaster } from "react-hot-toast";
import { handleApiError } from "../../helper/helperFunction";
import { getPrizes } from "../../API/Prizes/getPrizes";
import { setPrize, setPrizeCount } from "../slices/getAllPrizes";
import { AddPrize } from "../../API/AddPrize/AddPrize";
import { EditPrizeApi } from "../../API/EditPrize/EditPrizeApi";
import { DeletePrizeApi } from "../../API/Prizes/deletePrizes";

export const handleGetPrize = createAsyncThunk(
  "admin/get-prize",
  async ({ page, searchQuery, count, setLoading, cb }, { dispatch }) => {
    try {
      setLoading(true);
      const resp = await getPrizes(page, count, searchQuery);
      if (resp.success) {
        const prizeData = resp.data.prizeData;
        const prizeCount = resp.data.total;
        dispatch(setPrize(prizeData));
        dispatch(setPrizeCount(prizeCount));
        cb && cb();
      }
    } catch (error) {
      const message = handleApiError(error);
      toast.error(capitalizeSentence(message));
    } finally {
      setLoading(false);
    }
  }
);


/* ==================Create Prize====================================== */
export const handleCreatePrize = createAsyncThunk(
  "verified/create-prize",
  async ({ data, setLoading, cb }, { dispatch, getState }) => {
    try {
      setLoading(true);

      const formData = new FormData();
      formData.append("title", data.title);
      formData.append("details", data.details);
      formData.append("prizelogo", data.prizelogo[0]);
      const resp = await AddPrize(formData);
      const respo = await getPrizes();
      if (resp.success && respo.success) {
        const prizeData = respo.data.prizeData;
        const prizeCount = respo.data.total;
        dispatch(setPrize(prizeData));
        dispatch(setPrizeCount(prizeCount));
        toast.success("Prize added successfully");
        cb();
      }
    } catch (error) {
      const message = handleApiError(error);
      toast.error(capitalizeSentence(message));
    } finally {
      setLoading(false);
    }
  }
);



export const handleEditPrize = createAsyncThunk(
  "giveaway/EditPrize",
  async ({ data, setLoading, cb, upload }, { dispatch, getState }) => {
    let toastId;
    try {
      setLoading(true);
      // if upload true this will show this toast
      if (upload) {
        toastId = toast.loading("Uploading...");
      }

      const resp = await EditPrizeApi(data);
      const respo = await getPrizes();
      if (resp.success && respo.success) {
        const prizeData = respo.data.prizeData;
        const prizeCount = respo.data.total;
        dispatch(setPrize(prizeData));
        dispatch(setPrizeCount(prizeCount));
        if (upload) {
          //if upload true this toast will show
          toast.success("Logo uploaded successfully...", { id: toastId });
        } else {
          toast.success("Updated successfully");
        }
        cb();
      }
    } catch (error) {
      toast.error("Upload failed", { id: toastId });
      const message = handleApiError(error);
      toast.error(capitalizeSentence(message));
    } finally {
      setLoading(false);
      cb();
    }
  }
);


export const handleDeletePrize = createAsyncThunk(
  "prize/delete-prize",
  async ({ id, setLoading, cb }, { dispatch }) => {
    try {
      setLoading(true);

      const resp = await DeletePrizeApi(id);
      const respo = await getPrizes();
      if (resp.success && respo.success) {
        const prizeData = respo.data.prizeData;
        const prizeCount = respo.data.total;
        dispatch(setPrize(prizeData));
        dispatch(setPrizeCount(prizeCount));
        toast.success("Prize deleted successfully");
        cb();
      }
    } catch (error) {
      const message = handleApiError(error);
      toast.error(capitalizeSentence(message));
    } finally {
      setLoading(false);
    }
  }
);