import Button from "react-bootstrap/Button";
import HelpModal from "../Modals/HelpModal";
import React from "react";
import { Col, Row } from "react-bootstrap";

const CommonDashboardHeader = ({ onClickShow, text, title }) => {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <div className="content-header p-8">
      <div className="row align-items-center">
        <div className="col">
          <h3>{title}</h3>
          <p className="fw-500">{text}</p>
        </div>
        <div className="col-auto">
          <Button variant="white" className="border-0 px-0" onClick={onClickShow}>
            {/* <span className="fsr-18 fw-700">My Account</span> */}
              <img src="/img/account.png" alt="edit" className="accountImg" />
          </Button>
        </div>
        {/* <div className="col-auto">
          <div className="d-flex helpBtnDiv">
            <Button
              variant="white border-0 px-0"
              className="px-0 py-0 hoverBtn"
              onClick={() => setModalShow(true)}
              size="lg"
            >
              <img src="./img/helpLogo.svg" alt="help-logo" />
            </Button>
          </div>
        </div> */}
      </div>
      <HelpModal show={modalShow} onHide={() => setModalShow(false)} />
    </div>
  );
};

export default CommonDashboardHeader;
