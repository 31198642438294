import { Button, Form } from "react-bootstrap";
import { UploadIcon } from "../../staticfiles/svgPack";
import { useForm } from "react-hook-form";
import { ImageValidation_size } from "../../helper/helperFunction";
import { IMAGE_FORMAT_ALLOWED } from "../../Config/config";
import { IMAGE_MAX_SIZE } from "../../Config/config";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { FormErrorMessage } from "../../staticfiles/FormMessage";
import { handleCreatePrize } from "../../Redux/actions/prizes";
const AddPrizeForm = ({ onClose }) => {
  const [loading, setLoading] = useState(false);
  const [fileUpload, setFileUpload] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const dispatch = useDispatch();
  const cb = () => onClose();
  const onSubmit = (data) => {
    let obj = { data, cb, setLoading };
    dispatch(handleCreatePrize(obj));
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group className="mb-3" controlId="formBasicInput">
        <Form.Label>
          <p className="labelTextV1 mb-0">Title</p>
        </Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter Prize Title"
          className="inputV2"
          {...register("title", {
            required: {
              value: true,
              message: FormErrorMessage.prizeTitle,
            },
          })}
          disabled={loading}
        />
        {errors?.title && (
          <p className="mb-0 ms-3 errorMsgV1 visible">{errors.title.message}</p>
        )}
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicInput">
        <Form.Label>
          <p className="labelTextV1 mb-0">Details</p>
        </Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter Details"
          className="inputV2"
          {...register("details", {
            required: {
              value: true,
              message: FormErrorMessage.prizedDetail,
            },
          })}
          disabled={loading}
        />
        {errors?.details && (
          <p className="mb-0 ms-3 errorMsgV1 visible">
            {errors.details.message}
          </p>
        )}
      </Form.Group>

      <h3 className="fsr-16 fw-700 my-4 pt-2">Prize Logo</h3>
      <Form.Group className="mb-3" controlId="formBasicInput">
        <Button
          variant="outline-primary"
          className="position-relative p-0 w-100"
        >
          <Form.Control
            type="file"
            className="position-absolute top-0 bottom-0 left-0 right-0 opacity-0"
            {...register("prizelogo", {
              required: {
                value: true,
                message: FormErrorMessage.prizeLogo,
              },
              validate: {
                //if it return false=> right hand operand work and throw error message .
                imgValidationType: (file) =>
                  ImageValidation_size(file[0], IMAGE_FORMAT_ALLOWED) ||
                  FormErrorMessage.imageFormat,

                imgValidationSize: (file) =>
                  ImageValidation_size(
                    file[0],
                    IMAGE_FORMAT_ALLOWED,
                    IMAGE_MAX_SIZE
                  ) || FormErrorMessage.imageSizeUpto5mb,
              },
            })}
            onChange={() => setFileUpload(true)}
            disabled={loading}
            accept="image/*"
          />
          <span className="d-flex justify-content-center align-items-center gap-2">
            <UploadIcon />
            <p className="mb-0 fsr-14 fw-500">
              {fileUpload ? "File Uploaded" : "Browse File"}
            </p>
          </span>
        </Button>
        {errors?.prizelogo && (
          <p className="mb-0 ms-3 errorMsgV1 visible">
            {errors.prizelogo.message}
          </p>
        )}
      </Form.Group>

      <div className="d-flex align-items-center justify-content-start gap-2 mb-4">
        <img src="/img/infoV1.svg" alt="" />
        <p className="mb-0 fsr-12 text-gray-v2 fw-500">File Size upto 5 mb</p>
      </div>

      <Button
        variant="primary"
        type="submit"
        className="w-100 mt-3 fsr-14 fw-700"
        disabled={loading}
      >
        {!loading ? "Save" : "Uploading..."}
      </Button>
    </Form>
  );
};

export default AddPrizeForm;
