import { createSlice } from "@reduxjs/toolkit";

const namespace = "admin";
const initialState = {
  dealData: null,
  dealCount: null,
    categoryData: null,
};

const getDealsSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    setDeals: (state, action) => {
      state.dealData = action.payload;
    },
    setDealsCount: (state, action) => {
      state.dealCount = action.payload;
    },
    setCategory: (state, action) => {
      state.categoryData = action.payload;
    },

    clearDealData: () => initialState,
  },
});

export const { setDealsCount, clearDealData, setDeals, setCategory } =
  getDealsSlice.actions;
export default getDealsSlice.reducer;
