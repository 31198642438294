import { useState } from "react";
import "../../App.scss";
import CommonDashboardHeader from "../CommonHeader/CommonDashboardHeader";
import EditProfileModal from "../Modals/EditProfileModal";
import RPDashboard from "./RPDashboard";
import BranchDetails from "./BranchDetails";

const RentlyPro = () => {
  const [modalShowEditProfile, setModalShowEditProfile] = useState(false);
  const [singleBranchData, setSingleBranchData] = useState(null);
  return (
    <>
      <div className="App">
        <div className="wrapper">
          <div className="content-wrapper h-full">
            <CommonDashboardHeader
              text={"Manage RentlyPro paid users."}
              title={"RentlyPro"}
              onClickShow={() => setModalShowEditProfile(true)}
            />
            <RPDashboard setSingleBranchData={setSingleBranchData} />
          </div>
        </div>
      </div>
      <EditProfileModal
        show={modalShowEditProfile}
        onHide={() => setModalShowEditProfile(false)}
      />
    </>
  );
};

export default RentlyPro;
