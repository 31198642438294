const regx = {
  email: new RegExp(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/),
  number: new RegExp(/^\d*\.?\d*$/),
  phoneNumber: new RegExp(/^[0-9]{10,}$/),
  date: new RegExp(/^\d{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/),
  url: new RegExp(
    /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g
  ),
  isNumeric: /^[0-9]*$/,
  isNumericWithDot :/^[0-9.]*$/
};

export default regx;
