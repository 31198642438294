import { asyncFunc } from "../common/asyncFun";
import { apiEndPoints } from "../common/endpoints";
const url = import.meta.env.VITE__APP_API;

export const ForgetPassword = async (data) => {
  //setting up config for forget password
  
  const config = {
    method: "post",
    url: url + apiEndPoints.Admin.forgetPassword,
    data,
  };

  return await asyncFunc(config);
};
