import InputEditElement from "./InputElem";
import { Button } from "react-bootstrap";
import { FormErrorMessage } from "../../staticfiles/FormMessage";
const PasswordOperation = ({
  registerPassword,
  onSubmitEditPassword,
  errorsPassword,
  loading,
  watch,
  handleSubmitPassword,
  setEditShow,
  showEdit,
}) => {
  return (
    <div className="position-relative inputV1">
      {!showEdit.isEditPassword ? (
        <InputEditElement
          type="password"
          placeholder={"************"}
          className="h-100"
          disabled={!showEdit?.isEditPhone}
        />
      ) : (
        <>
         
          <InputEditElement
            type="password"
            placeholder="Current password"
            className="h-100"
            register={registerPassword}
            name={"previous"}
            validationSchema={{
              required: {
                value: true,
                message: FormErrorMessage?.password,
              },
              minLength: {
                value: 6,
                message: FormErrorMessage?.passwordFormat,
              },
            }}
            errors={errorsPassword?.previous}
          />
           <InputEditElement
            type="password"
            placeholder="New  password"
            className="h-100"
            register={registerPassword}
            name={"current"}
            validationSchema={{
              required: {
                value: true,
                message: FormErrorMessage?.password,
              },
              minLength: {
                value: 6,
                message: FormErrorMessage?.passwordFormat,
              },
            }}
            errors={errorsPassword?.current}
          />
          <InputEditElement
            type="password"
            placeholder={"Confirm password"}
            className="h-100"
            register={registerPassword}
            name={"confirmPassword"}
            validationSchema={{
              required: {
                value: true,
                message: FormErrorMessage?.password,
              },
              minLength: {
                value: 6,
                message: FormErrorMessage?.passwordFormat,
              },
              validate: (value) =>
                watch("current") === value || FormErrorMessage?.matchPassword
            }}
            errors={errorsPassword?.confirmPassword}
          />
        </>
      )}
      <Button
        variant="dark"
        className="position-absolute end-0 top-0 h-100 fw-700 px-3 min-w-100px"
        onClick={
          showEdit.isEditPassword
            ? handleSubmitPassword(onSubmitEditPassword)
            : () =>
                setEditShow({
                  ...showEdit,
                  isEditPassword: !showEdit.isEditPassword,
                })
        }
        disabled={loading}
      >
        {showEdit.isEditPassword ? "Submit" : "Edit"}
      </Button>
    </div>
  );
};

export default PasswordOperation;
