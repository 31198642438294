import "./Sidebar.scss";
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import NAV_LINKS from "../../Config/config";
// import { Support } from "svgPack";
import { useLocation } from "react-router-dom";
function Sidebar() {
  let location = useLocation();

  return (
    <div className="Sidebar ">
      <div className="logo">
        <span>
          <img src="/img/logo.svg" alt="" />
        </span>
      </div>
      <Nav className="hey">
        {NAV_LINKS.map((el, index) => (
          <Nav.Item key={index}>
            <Link
              className={`nav-link ${
                location.pathname === el.to ? "active" : ""
              }`}
              to={el.to}
            >
              <span className="material-icons">{el.icons}</span>
              <div className="link-name">{el.title}</div>
            </Link>
          </Nav.Item>
        ))}
      </Nav>
    </div>
  );
}

export default Sidebar;
